import React from "react"
import { Link } from "gatsby"
import { usePageContext } from "../../pageContext/pageContext"

import Menu from "../../menu/menu"

interface Props {
  dropdowns?: any
  name: string
  link?: string
  value?: string
}

const MenuElemMobile = ({ items }: any) => {
  const { locale } = usePageContext() as any
  return items.map(({ name, link, dropdowns }: Props, key: number) => (
    <div className="sova-menu-item-wrapper sova-menu-item-wrapper_with-dropdown" key={key}>
      {link ? (
        <Link to={`${link === "/mainDocPage" ? link : locale + link}`} className="sova-menu-item sova-section-child sova-section-child_headerMenu-mobile sova-section-child_headerMenu-mobile_link">
          {name}
        </Link>
      ) : (
        <p className="sova-menu-item sova-section-child sova-section-child_headerMenu-mobile">{name}</p>
      )}
      {dropdowns && <Menu items={dropdowns} textClassName="header" className="sova-menu-item sova-menu-item_heaerMenu-mobile" />}
    </div>
  ))
}

export default MenuElemMobile
