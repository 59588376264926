import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import SEO from "../components/SEO/seo"
import ProductsSections from "../pagesStructures/products/productsSections"

const Products = ({ data, pageContext }: { data: any; pageContext: any }) => {
  const pageContent = data.strapiProducts.content.find((el: any) => {
    return el.language === pageContext.lang
  })
  return (
    <Layout bgCustomClass="products">
      <SEO title={pageContent.content.title} />
      <ProductsSections data={{ ...pageContent, pageContext: pageContext }} />
    </Layout>
  )
}

export default Products
export const query = graphql`
  query Products($url: String) {
    strapiProducts(url: { eq: $url }) {
      content {
        language
        content {
          title
          ProductsSectionFirstConfig {
            btnLink
            btnText
            heading
            link
            paragraph
          }
          ProductsSectionSecondConfig {
            heading
            products {
              describtion
              link
              title
              learnMoreBtn
            }
          }
        }
      }
    }
  }
`
