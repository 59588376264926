import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useInView } from "react-intersection-observer"
import { usePageContext } from "../pageContext/pageContext"

// children components
import Nav from "../navigation/Nav"
import LanguageSwitcher from "../languageSwitcher/languageSwitcher"
import MenuElemMobile from "./components/MenuElemMobile"
import MenuElemDesktop from "./components/MenuElemDesktop"

// assets
import logo from "../../assets/Logo.svg"
import mobileMenuIconOpen from "../../assets/mobile-menu-icon-open.svg"
import mobileMenuIconClose from "../../assets/mobile-menu-icon-close.svg"
import gitHubIcon from "../../assets/icons/github-icon-sova.ai.svg"

interface Props {
  pageName?: string | boolean
}

interface $linkType {
  [k: string]: () => string
}

const config = {
  gitHubLink: {
    Puzzlelib: () => "https://github.com/puzzlelib/PuzzleLib",
  } as $linkType,
}

const Header = ({ pageName }: Props) => {
  const { strapiHeader } = useStaticQuery(
    graphql`
      query {
        strapiHeader(name: { eq: "general" }) {
          content {
            content {
              btnLink
              btnName
              menuItems {
                articles {
                  figcaption
                  imgAlt
                  imgSrc
                  link
                }
                dropdowns {
                  link
                  describtion
                  name
                  foreignLink
                }
                link
                name
                value
                foreignLink
              }
            }
            language
          }
          name
        }
      }
    `
  )

  const [ref, inView] = useInView({
    threshold: 0,
  })

  const { locale, lang } = usePageContext() as any

  const pageContent = strapiHeader.content.find((el: any) => {
    return el.language === lang
  })

  const { gitHubLink } = config
  const { menuItems, btnName, btnLink } = pageContent.content

  const [mobile, changeStatus] = useState(false)

  const body = typeof document !== "undefined" ? document.querySelector("body") : null
  mobile && inView ? body?.classList.add("sova-body-mobile") : body?.classList.remove("sova-body-mobile")

  return (
    <header className={`sova-header sova-row sova-row_centered${mobile && inView ? " active" : ""}`}>
      <div className={`sova-preheader sova-row sova-row_justify-between sova-row_centered sova-row_with-max-width-1317 ${mobile && inView ? " active" : ""}`}>
        <Link to={`${locale !== "" ? locale + "/" : "/"}`} className="sova-logo">
          <img src={logo} className="sova-section-img" />
        </Link>
        <div className={`sova-section-container sova-section-container_row sova-section-container_navbar sova-mobile${mobile && inView ? " active" : ""}`}>
          <Nav>{mobile && inView ? <MenuElemMobile items={menuItems} /> : <MenuElemDesktop items={menuItems} />}</Nav>
          {!inView && (
            <a href={gitHubLink.hasOwnProperty(pageName as string) ? gitHubLink[pageName as string]() : btnLink} className="sova-socialLink_header">
              <button className="sova-btn sova-btn_secondary sova-btn_small">
                <img src={gitHubIcon} alt="GitHub icon - sova.ai" className="sova-icon sova-icon_with-margin-right sova-icon_white" />
                {btnName}
              </button>
            </a>
          )}

          {!inView && <LanguageSwitcher />}
        </div>
        <button
          className="sova-btn sova-btn_nav"
          ref={ref}
          onClick={() => {
            changeStatus(!mobile)
          }}
        >
          {mobile ? (
            <img src={mobileMenuIconClose} alt="Close Icon Mobile" className="sova-icon sova-section-img" />
          ) : (
            <img src={mobileMenuIconOpen} alt="Open Icon Mobile" className="sova-icon sova-section-img" />
          )}
        </button>
      </div>
    </header>
  )
}

export default Header
