import React, { FC } from "react"
import Header from "../components/header/Header"
import Footer from "../components/footer/Footer"
import Main from "../components/main/Main"
import SEO from "../components/SEO/seo"
import Background from "../components/background/Background"
export interface LayoutProps {
  children?: React.ReactNode
  bgEnabled?: boolean
  footerEnabled?: boolean
  bgCustomClass?: string | boolean
  mcCustomClass?: string | boolean
  mCustomClass?: string | boolean
  pageName?: string | boolean
}

const Layout: FC<LayoutProps> = ({ children, bgEnabled = true, footerEnabled = true, bgCustomClass = "false", mcCustomClass = "false", mCustomClass = "false", pageName }) => {
  return (
    <>
      <div className={`sova-wrapper-container sova-wrapper-container-custom_${mcCustomClass}`}>
        <Header pageName={pageName} />
        <SEO title="Home" />
        <Main className={`sova-main-container sova-main-container-custom_${mCustomClass}`}>{children}</Main>
        {footerEnabled && <Footer pageName={pageName} />}
        {bgEnabled && <Background className={`sova-bg sova-bg-custom_${bgCustomClass}`} />}
      </div>
    </>
  )
}

Layout.displayName = "Layout"

export default Layout
