import React from "react"
import { createPortal } from "react-dom"

interface BackgroundProps {
  children?: any
  className?: string
}

const Background = ({ children, className }: BackgroundProps) => {
  return typeof document !== "undefined" ? createPortal(<div className={className}>{children}</div>, document.body) : null
}

Background.defaultProps = {
  className: "",
}

export default Background
