import React from "react"
import Section from "../../../components/section/Section"

const ProductsSectionFirst = ({ className, data }: { className: string; data: any }) => {
  const { heading, paragraph, btnText, btnLink, link } = data.data.content.ProductsSectionFirstConfig

  return (
    <Section className={`${className} sova-fullscreen-height`}>
      <h1 className="sova-section-child sova-section-child_puzzlelib-1 sova-h1 sova-h1_centered">{heading}</h1>
      <p className="sova-section-child sova-section-child_puzzlelib-1 sova-h4 sova-common-text-centered">{paragraph}</p>
      <div className="sova-section-child-container sova-section-child-container_row">
        <a href={btnLink} id="sova-products-0_getStartedBtn">
          <button className="sova-btn sova-btn_primary sova-btn_big">{btnText}</button>
        </a>
        <a className="sova-h4 sova-h4_link sova-section-child_products-0-link" href="#">
          {link}
        </a>
      </div>
    </Section>
  )
}

ProductsSectionFirst.displayName = "PuzzlelibSectionSecond"

export default ProductsSectionFirst
