import React, { useState, useEffect } from "react"
import { useStoreon } from "../../store/store"

import LanguageMenu from "./languageMenu"
import { languageConfig } from "./languageMenu"
import { usePageContext } from "../pageContext/pageContext"

const LanguageSwitcher = () => {
  const { settings } = useStoreon("settings")

  const { language } = settings
  const { languageText } = language
  const { dispatch } = useStoreon()

  const { lang } = usePageContext() as any

  const storeLanguage = languageConfig.find((item: any) => {
    return item.languageText === lang
  })

  useEffect(() => {
    dispatch("updateLanguage", storeLanguage)
  }, [lang])

  const [showDropDownStatus, setShowDropDownStatus] = useState(false)

  return (
    <div className="sova-menu-item-wrapper sova-menu-item-wrapper_with-dropdown sova-menu-item-wrapper_with-dropdown-languageSwitcher">
      <div className="sova-menu-item" onClick={() => setShowDropDownStatus(!showDropDownStatus)}>
        {languageText.toUpperCase()}
      </div>
      <LanguageMenu setShowDropDownStatus={setShowDropDownStatus} lang={languageText} />
    </div>
  )
}

export default LanguageSwitcher
