import React from "react"
import { Link } from "gatsby"
import { usePageContext } from "../../pageContext/pageContext"
import Menu from "../../menu/menu"

interface Props {
  dropdowns?: any
  articles?: any
  name: string
  link?: string
  foreignLink?: string
  value?: string
}

const MenuElemDesktop = ({ items }: any) => {
  const { locale } = usePageContext() as any

  return items.map(({ name, link, dropdowns, foreignLink, value }: Props, key: number) => {
    return (
      <div className={`sova-menu-item-wrapper${dropdowns ? " sova-menu-item-wrapper_with-dropdown" : ""}`} key={key}>
        {link ? (
          foreignLink ? (
            <a href={`${link}`} className="sova-menu-item" target="blank">
              {name}
            </a>
          ) : (
            <Link to={`${link === "/mainDocPage" ? link : locale + link}`} className="sova-menu-item">
              {name}
            </Link>
          )
        ) : (
          <p className="sova-menu-item">{name}</p>
        )}
        {dropdowns && (
          <div className={`sova-section-container-for-dropdown ${value === "products" ? " sova-section-container-for-dropdown_prod" : ""} `}>
            <div className={`sova-dropdown-menu-desktop${value === "company" ? " sova-dropdown-menu-desktop_short" : ""} `}>
              <ul className={`sova-dropdown-menu sova-dropdown-menu_${key}`}>{<Menu items={dropdowns} textClassName="header" className="sova-menu-item" />}</ul>
              {/* <div className="sova-section-container sova-section-container_row sova-section-container_header-menu-articles-container">
              {articles.map(({ imgSrc, imgAlt, figcaption, link }: { imgSrc: string; imgAlt: string; figcaption: string; link: string }, key: number) => (
                <div className="sova-section-container sova-section-container_header-dropdown-menu-article" key={key}>
                  <img src={images[imgSrc]} alt={imgAlt} className="sova-section-child sova-section-child_header-dropdown-menu-article" />
                  <p className="sova-section-child sova-section-child_header-dropdown-menu-article sova-section-child_header-dropdown-menu-article-figcaption">{figcaption}</p>
                  <Link className="sova-section-link" to={`${locale}${link}`}>
                    Read more
                    <img src={arrowRight} className="sova-header-dropdown-menu-article-linkIcon" alt="Arrow right icon - sova.ai" />
                  </Link>
                </div>
              ))}
            </div> */}
            </div>
          </div>
        )}
      </div>
    )
  })
}

export default MenuElemDesktop
