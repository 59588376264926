import React from "react"

import ProductsSectionFirst from "./productsSections/productsSectionFirst"
import ProductsSectionSecond from "./productsSections/productsSectionSecond"

const sections = [
  { component: (key: number, className: string, data: any) => <ProductsSectionFirst data={data} className={className} key={key} /> },
  { component: (key: number, className: string, data: any) => <ProductsSectionSecond data={data} key={key} className={className} /> },
]

const ProductsSections = (data: any) => {
  return <>{sections.map(({ component }, i) => component(i, `sova-section sova-section-products  sova-section-products_${i}`, data))}</>
}

ProductsSections.displayName = "ProductsSections"

export default ProductsSections
