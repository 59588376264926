import React from "react"
import { Link } from "gatsby"
import Section from "../../../components/section/Section"

interface Product {
  title: string
  describtion: string
  link: string
  learnMoreBtn: string
}

const ProductsSectionSecond = ({ className, data }: { className: string; data: any }) => {
  const { heading, products } = data.data.content.ProductsSectionSecondConfig

  return (
    <Section className={`${className} sova-section-without-margin-bottom`}>
      <h2 className="sova-section-child sova-h2">{heading}</h2>
      <div className="sova-section-container sova-section-container_row sova-section-container_products-1">
        {products.map(({ title, describtion, link, learnMoreBtn }: Product, key: number) => (
          <div className="sova-section-child-container sova-section-child-container_products-1" key={key}>
            <h3 className="sova-section-child sova-section-child-text_products-1 sova-h3">{title}</h3>
            <p className="sova-section-child sova-section-child_products-1 sova-p-tiny">{describtion}</p>
            <Link to={`/${link}`}>
              <button className="sova-btn sova-btn_secondary sova-btn_small sova-btn">{learnMoreBtn}</button>
            </Link>
          </div>
        ))}
      </div>
    </Section>
  )
}

ProductsSectionSecond.displayName = "ProductsSectionSecond"

export default ProductsSectionSecond
